import * as React from 'react';

import * as auth from '../helpers/auth';

const authContext = React.createContext();

export function AuthProvider(props) {
  const [user, setUser] = React.useState(auth.getToken());

  React.useEffect(() => {
    auth.userInfo().then((response) => {
      if (response.success) {
        setUser(response.user);
      } else {
        setUser(null);
      }
    });
  }, []);

  const login = React.useCallback(
    (form) =>
      auth.login(form).then((response) => {
        if (!response.success) {
          setUser(null);
          throw new Error('Login failed');
        } else if (response.success && !response.second_password) {
          setUser(response.user);
        }
        return response;
      }),
    [setUser]
  );

  const secondPassword = React.useCallback(
    (form) =>
      auth.secondPassword(form).then((response) => {
        if (response.success) {
          setUser(response.user);
        } else {
          setUser(null);
          throw new Error('Second password failed');
        }
      }),
    [setUser]
  );

  const logout = React.useCallback(() => {
    auth.logout().then((response) => {
      if (response.success) {
        setUser(null);
      } else {
        console.log('Logout went wrong');
      }
    });
  }, [setUser]);

  const value = React.useMemo(
    () => ({ user, login, secondPassword, logout }),
    [user, login, secondPassword, logout]
  );

  return <authContext.Provider value={value} {...props} />;
}

export default function AuthConsumer() {
  const context = React.useContext(authContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}
