function getCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const Token = getCookie('csrftoken');

function eraseCookie(name) {
  document.cookie = `${name}=; Max-Age=-99999999;`;
}

function doesHttpOnlyCookieExist(cookiename) {
  var d = new Date();
  d.setTime(d.getTime() + 1000);
  var expires = 'expires=' + d.toUTCString();

  document.cookie = cookiename + '=new_value;path=/;' + expires;
  if (document.cookie.indexOf(cookiename + '=') === -1) {
    return true;
  } else {
    return false;
  }
}

export { getCookie, Token, eraseCookie, doesHttpOnlyCookieExist };
