import { doesHttpOnlyCookieExist, getCookie } from './csrftoken';

const getToken = () => {
  return doesHttpOnlyCookieExist('sessionid') ? getCookie('csrftoken') : null;
};

const login = async ({ username, password }) => {
  const logged = await client('login', { username, password });
  return logged;
};

const secondPassword = async (postData) => {
  const res = await client('second-password', postData);
  return res;
};

const logout = async () => {
  await client('logout');
  return { success: true };
};

const userInfo = async () => {
  const info = await client('info');
  return info;
};

const client = async (endpoint, data) => {
  const config = {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'X-CSRFToken': getCookie('csrftoken')
    }
  };

  return window.fetch(`${window.location.origin}/cms/auth/${endpoint}/`, config).then(async (response) => {
    const data = await response.json();
    if (response.ok) return data;
    else return Promise.reject(data);
  });
};

export { getToken, login, secondPassword, logout, userInfo };
