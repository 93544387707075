import { Suspense, lazy } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from 'react-router-dom';

import useAuth from './hooks/useAuth';

const Authenticated = lazy(() => import('./components/Authenticated'));
const Unauthenticated = lazy(() => import('./components/Unauthenticated'));

export default function App() {
  const { user } = useAuth();

  return (
    <Suspense fallback={<></>}>
      <Router>{user ? <Authenticated /> : <Unauthenticated />}</Router>
      <Toaster />
    </Suspense>
  );
}
